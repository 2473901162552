export function awsUrl() {
  const apiUrl =
    // "https://a4c2uh4pka.execute-api.ap-south-1.amazonaws.com/test/purplehealth";
    "https://a4c2uh4pka.execute-api.ap-south-1.amazonaws.com/prod_Token/purplehealth";
  return apiUrl;
}

export function awsUrlcommon() {
  const apiUrl =
    // "https://a4c2uh4pka.execute-api.ap-south-1.amazonaws.com/test/purplehealthcommon";
    "https://a4c2uh4pka.execute-api.ap-south-1.amazonaws.com/prod_Token/purplehealthcommon";
  return apiUrl;
}

export function apiUrlReliance() {
  const apiUrlReliance =
    // "https://a4c2uh4pka.execute-api.ap-south-1.amazonaws.com/reliancetest/purplehealth";
    "https://a4c2uh4pka.execute-api.ap-south-1.amazonaws.com/relianceProd_Token/purplehealth";
  return apiUrlReliance;
}

export function S3ImagePath() {
  // const S3Link ="https://purplehealthfiles.s3.amazonaws.com/prod";
  const S3Link = "https://purplehealthfiles.s3.amazonaws.com/production/theme"; //!Production Url 
  return S3Link;
}
export function elasticLink() {
  const elsticLink = "ph-prod-search"; //!care full here 
  // const elsticLink = "ph-test-search";
  return elsticLink;
}
export function apiUrlRelianceElasticSearch() {
  const apiUrlRelianceElasticSearch =
    // "https://ki2slqvnhb.execute-api.ap-south-1.amazonaws.com/test/elasticsearch";
    "https://ki2slqvnhb.execute-api.ap-south-1.amazonaws.com/prod/elasticsearch";
  return apiUrlRelianceElasticSearch;
}
export function jwtSecret() {
  const jwtSecret = "Xilli1123581321$";
  return jwtSecret;
}

