import React, { Component, Suspense } from "react";
import { apiCalling } from "../apiService";
import $ from "jquery";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import AWS from "aws-sdk";
import showImg from "../image/icons/blogging.png";
import download from "../image/icons/download.png";
import cancel from "../image/icons/sh-cancel.png";
import pdf from "../image/icons/pdf.png";
import attachment from "../image/icons/attachment.png";
import { uploadToS3 } from "../UploadFile";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
var s3;

var imageFile;

export default class AddJournal extends Component {
  state = {
    titleTxt: "",
    siteDataTxt: "",
    fileNameOnly: "",
    fileNameWithPath: "",
    imageNameWithPath: [],
    imageName: [],
    imageNameWithPathLocal: [],
    imageNameLocal: [],
    siteList: [],
    AwsCheckAccessKey: "",
    AWSAccessKeyId: "",
    region: "us-east-1",
    bucket: "",
    bucketFolder: "",
    S3BucketBlogImages: "",
    editorState: EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML("<p></p>"))
    ),
  };

  componentDidMount() {
    this.imageUploadHandler();
    this.getSiteDetails();
    if (this.props.match.params.journalId) {
      this.getjournalDetails();
    }
  }

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  getSiteDetails() {
    const siteDetail = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getAllSiteDetails",
      }),
    };
    apiCalling(siteDetail).then((data) => {
      if (data.success === "1") {
        this.setState({
          siteList: data.siteData,
        });
      }
    });
  }

  getjournalDetails() {
    const journalData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getJournalsListing",
        siteId: window.sessionStorage.getItem("siteId"),
        journalId: this.props.match.params.journalId,
      }),
    };
    $(".loader").show();
    apiCalling(journalData).then((data) => {
      $(".loader").hide();
      if (data.success === "1") {
        this.setState({
          titleTxt: data.result[0].journalTitle,
          fileNameWithPath: data.result[0].fileNameWithPath,
          fileNameOnly: data.result[0].fileNameOnly,
          imageName:
            data.result[0].imageNameOnly == ""
              ? []
              : data.result[0].imageNameOnly.split(","),
          imageNameWithPath:
            data.result[0].imageNameWithPath == ""
              ? []
              : data.result[0].imageNameWithPath.split(","),
          siteDataTxt: data.result[0].siteId,
          editorState: EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(data.result[0].description)
            )
          ),
        });
      }
      $(".loader").hide();
    });
  }

  uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  imageUploadHandler() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "imagePath",
        siteId: window.sessionStorage.getItem("siteId"),
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        // this.setState({
        //   AwsCheckAccessKey: data.configPathDetails[0].AwsCheckAccessKey,
        // });
        // this.setState({
        //   AWSAccessKeyId: data.configPathDetails[0].AwsCheckSecretKeyId,
        // });
        this.setState({
          bucket: data.configPathDetails[0].PurpleHealthS3bucket,
        });
        this.setState({
          bucketFolder: data.configPathDetails[0].S3BucketMainFolder,
        });
        this.setState({
          S3BucketBlogImages: data.configPathDetails[0].S3BucketBlogImages,
        });
      }
    });
  }

  fileChange = (e) => {
    imageFile = e.target.files[0];
    if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
      alert("Add file format with only JPG,JPEG,PNG format");
      return false;
    }
    if (imageFile.size > 30145728) {
      alert("File size must under 30 MB!");
      return false;
    } else {
      var fileObj = e.target.files[0];
      this.setState({ fileNameWithPath: window.URL.createObjectURL(fileObj) });
      this.fileUpload(imageFile);
    }
  };

  async fileUpload(file) {
    $(".loader").show();
    // AWS.config.update({
    //   accessKeyId: this.state.AwsCheckAccessKey,
    //   secretAccessKey: this.state.AWSAccessKeyId,
    //   region: this.state.region,
    // });
    // var bucketParams = {
    //   Bucket:
    // this.state.bucket +
    // "/" +
    // this.state.bucketFolder +
    // "/" +
    // this.state.S3BucketBlogImages,
    // };

    // s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
    let S3BucketMainFolder = "";
    let fileName = this.uuidv4() + file.name;
    // let fullFilePath = S3BucketMainFolder + fileName;
    imageFile = fileName;
    const s3Url = await uploadToS3(file, this.state.bucketFolder + "/" + this.state.S3BucketBlogImages, fileName, this.state.bucket)
    if (s3Url) {
      this.setState({
        fileNameWithPath: s3Url,
        fileNameOnly: fileName,
      });
      return null;
    } else {
      alert("There was an error uploading your photo: ");
    }
    // await s3.upload(
    //   {
    //     Key: fullFilePath,
    //     Body: file,
    //     ACL: "public-read",
    //   },
    //   (err, data) => {
    //     $(".loader").hide();
    //     if (err) {
    //       alert("There was an error uploading your photo: ", err.message);
    //     } else if (data) {
    //       this.setState({
    //         fileNameWithPath: data.Location,
    //         fileNameOnly: fileName,
    //       });
    //       return null;
    //     }
    //   }
    // );
  }

  multipleFileChange = (e) => {
    let files = e.target.files;
    for (var i = 0; i < files.length; i++) {
      let imageFile = files[i];
      if (!imageFile.name.match(/\.(jpg|jpeg|png|pdf)$/)) {
        alert("Add file format with only JPG,JPEG,PNG format");
        return false;
      }
      if (imageFile.size > 30145728) {
        alert("File size must under 30 MB!");
        return false;
      } else {
        var fileObj = e.target.files[0];
        let imageNameArrLocal = this.state.imageNameLocal;
        let imageNameWithPathArrLocal = this.state.imageNameWithPathLocal;
        imageNameArrLocal.push(imageFile.name);

        let fileName = imageFile.name.match(/\.(pdf)$/)
          ? pdf
          : window.URL.createObjectURL(fileObj);
        imageNameWithPathArrLocal.push({
          name: fileName,
          isLocal: true,
          loading: 0,
        });

        this.setState({
          imageNameLocal: imageNameArrLocal,
          imageNameWithPathLocal: imageNameWithPathArrLocal,
        });

        this.uploadMutipleFile(imageFile);
      }
    }
  };

  async uploadMutipleFile(file) {
    let imageNameArr = this.state.imageName;
    let imageNameWithPathArr = this.state.imageNameWithPath;

    let imageNameArrLocal = this.state.imageNameLocal;
    let imageNameWithPathArrLocal = this.state.imageNameWithPathLocal;

    // AWS.config.update({
    //   accessKeyId: this.state.AwsCheckAccessKey,
    //   secretAccessKey: this.state.AWSAccessKeyId,
    //   region: this.state.region,
    // });
    // AWS.config.httpOptions.timeout = 0;

    // var bucketParams = {
    //   Bucket:
    //     this.state.bucket +
    //     "/" +
    //     this.state.bucketFolder +
    //     "/" +
    //     this.state.S3BucketBlogImages,
    // };

    // s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
    let S3BucketMainFolder = "";
    let fileName = this.uuidv4() + file.name;
    // let fullMultiplePath = S3BucketMainFolder + fileName;
    let fileIndex = imageNameArrLocal.indexOf(file.name);

    const s3Url = await uploadToS3(file, this.state.bucketFolder + "/" + this.state.S3BucketBlogImages, fileName, this.state.bucket)
    if (s3Url) {
      imageNameArrLocal.splice(fileIndex, 1);
      imageNameArr.push(fileName);
      imageNameWithPathArrLocal.splice(fileIndex, 1);
      imageNameWithPathArr.push(s3Url);
      this.setState({
        imageName: imageNameArr,
        imageNameWithPath: imageNameWithPathArr,
      });
    } else { return null; }
    // s3.upload(
    //   {
    //     Key: fullMultiplePath,
    //     Body: file,
    //     ACL: "public-read",
    //   },
    //   (err, data) => {
    //     if (err) {
    //       return null;
    //     } else {
    //       imageNameArrLocal.splice(fileIndex, 1);
    //       imageNameArr.push(fileName);
    //       imageNameWithPathArrLocal.splice(fileIndex, 1);
    //       imageNameWithPathArr.push(data.Location);
    //       this.setState({
    //         imageName: imageNameArr,
    //         imageNameWithPath: imageNameWithPathArr,
    //       });
    //     }
    //   }
    // ).on("httpUploadProgress", (evt) => {
    //   imageNameWithPathArrLocal[fileIndex].loading = Math.round(
    //     (evt.loaded / evt.total) * 100
    //   );
    //   this.setState({
    //     imageNameWithPathLocal: imageNameWithPathArrLocal,
    //   });
    // });
  }

  removeFile(filePath) {
    //Remove from imageNameWithPath
    let imageNameWithPath = this.state.imageNameWithPath;
    const imageNameWithPathIndex = imageNameWithPath.indexOf(filePath);
    if (imageNameWithPathIndex > -1) {
      imageNameWithPath.splice(imageNameWithPathIndex, 1);
    }

    //Remove from imageNameOnly
    let imageUrl = filePath.split("/");
    let imageUrlIndex = imageUrl.length - 1;

    let fileName = imageUrl[imageUrlIndex];
    let tempimageName = this.state.imageName;

    const index = tempimageName.indexOf(fileName);
    if (index > -1) {
      tempimageName.splice(index, 1);
    }
    this.setState({
      imageNameWithPath: imageNameWithPath,
      imageName: tempimageName,
    });
  }

  validateForm() {
    let editorState = draftToHtml(
      convertToRaw(this.state.editorState.getCurrentContent())
    );

    if (this.state.titleTxt === "") {
      alert("Title required.");
      $("#titleTxt").focus();
    } else if (this.state.fileNameWithPath == "") {
      alert("Blog Image required.");
      $("#blogImage").focus();
      return false;
    } else if (editorState == "") {
      alert("Detailed Description required.");
      return false;
    } else {
      return true;
    }
  }

  saveHandle = () => {
    if (this.validateForm()) {

      const saveField = {
        method: "POST",
        body: JSON.stringify({
          functionName: "saveJournals",
          siteId: window.sessionStorage.getItem("siteId"),
          journalId:
            this.props.match.params.journalId === "null"
              ? ""
              : this.props.match.params.journalId,
          fileName: this.state.fileNameOnly,
          title: this.state.titleTxt,
          description: draftToHtml(
            convertToRaw(this.state.editorState.getCurrentContent())
          ),
          imageName: this.state.imageName.join(),
        }),
      };
      $(".loader").show();

      apiCalling(saveField).then((data) => {
        $(".loader").hide();
        if (data.success === "1") {
          let message =
            this.props.match.params.journalId > 0
              ? "Updated successfully "
              : "Save successfully";
          alert(message);
          setTimeout(() => {
            window.location.href = "/journalListing";
          }, 2000);
        } else if (data.success === "0") {
          alert(data.errorMessage);
        }
      });
    }
  };

  cancelHandle() {
    window.location.href = "/journalListing";
  }

  srcTypeImage(ev) {
    ev.target.src = showImg;
  }

  render() {
    const { editorState } = this.state;

    return (
      <div className="purpleWrap">
        <Suspense fallback={<div className="loader"></div>}>
          <section>
            <Header /> <div className="loader"></div>
          </section>
        </Suspense>
        <div className="container">
          <div className="main-wrapper-smwidth1">
            <div className="col-md-12 p-0">
              <ul class="breadcrumb">
                <li>
                  <a href="/doctor/dashboard">Dashboard</a>
                  <i class="fa fa-chevron-right"></i>
                </li>
                <li>
                  <a href="/journalListing">
                    Journal List
                  </a>
                  <i class="fa fa-chevron-right"></i>
                </li>
                <li>
                  <a href="" className="current">
                    Add Journal
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-sm-12 form-head mb-0">Journal</div>
            <div className="form-section-brdr">
              <div className="row">
                <div className="row-margin col-lg-6">
                  <label className="col-sm-12 label-text">
                    Add Title <span className="star-red">*</span>
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="input-design"
                      placeholder="Blog Title"
                      id="titleTxt"
                      name="titleTxt"
                      value={this.state.titleTxt}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                <div className="col-sm-6 blog-fleupload-top px-5">
                  <div className="fileUpload-medical mr-3">
                    <span>Add Blog Image</span>
                    <input
                      type="button"
                      id="attach"
                      name="attach"
                      className="upload"
                    />
                    <input
                      type="file"
                      className="upload"
                      name="fileText"
                      id="fileText"
                      onChange={this.fileChange}
                    />
                  </div>
                  <div className="fileUpload-img">
                    <img
                      onError={this.srcTypeImage}
                      id="fileNameWithPath"
                      src={this.state.fileNameWithPath}
                      id="blogImage"
                      width="50"
                    ></img>
                  </div>

                  <p className="note">
                    Support File Type:JPEG,JPG,PNG
                  </p>
                </div>
                <div className="row-margin detldes-div col-sm-12 px-4">
                  <label className="col-sm-12 label-text pl-0">
                    Detailed Description{" "}
                  </label>
                  <div
                    className=""
                    style={{
                      minHeight: "400px",
                      borderWidth: "1px",
                      borderColor: "#dcdcdc",
                      borderStyle: "solid",
                    }}
                  >
                    <Editor
                      editorState={this.state.editorState}
                      initialEditorState={editorState}
                      toolbarclassName="toolbarclassName"
                      wrapperclassName="redactor-wrapper"
                      editorclassName="editorclassName"
                      onEditorStateChange={this.onEditorStateChange}
                      handlePastedText={() => false}
                    />
                  </div>
                </div>
                <div className="row-margin col-lg-12 mt-4">
                  <label className="col-sm-12 label-text">
                    <a className="fileUpload-medical">
                      Add Attacthments
                    </a>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      multiple
                      id="fileAttacthments"
                      onChange={this.multipleFileChange}
                    />
                  </label>
                  <div className="row col-sm-12 mt-3">
                    {this.state.imageNameWithPath.map((x) => {
                      if (x.split(".").pop() == "pdf") {
                        return (
                          <div key={x} className="attchment-adddiv mb-3">
                            <a onClick={() => this.removeFile(x)}>
                              <img
                                src={cancel}
                                className="attachment-cancelImg"
                              ></img>
                            </a>
                            <img src={pdf} width="100" height="100" style={{ objectFit: "cover", objectPosition: "center" }}></img>
                            <a
                              href={x}
                              target="_blank"
                              rel="noopener noreferrer"
                              download
                            >
                              <img
                                src={download}
                                className="attachment-downloadImg"
                              ></img>
                            </a>
                          </div>
                        );
                      } else {
                        return (
                          <div key={x} className="attchment-adddiv mb-3">
                            <a onClick={() => this.removeFile(x)}>
                              <img
                                src={cancel}
                                className="attachment-cancelImg"
                              ></img>
                            </a>
                            <img src={x} width="100" height="100" style={{ objectFit: "cover", objectPosition: "center" }}></img>
                            <a
                              href={x}
                              target="_blank"
                              rel="noopener noreferrer"
                              download
                            >
                              <img
                                src={download}
                                className="attachment-downloadImg"
                              ></img>
                            </a>
                          </div>
                        );
                      }
                    })}
                    {this.state.imageNameWithPathLocal.map((x) => {
                      return (
                        <div key={x} className="col-sm-2 attchment-adddiv">
                          <img src={x.name} width="100" height="100" style={{ objectFit: "cover", objectPosition: "center" }}></img>
                          <div
                            style={{
                              position: "absolute",
                              display: "block",
                              width: "130px",
                              height: "100px",
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              backgroundColor: "rgba(0, 0, 0, 0.5)",
                              zIndex: 2,
                              cursor: "pointer",
                            }}
                          >
                            <div
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                fontSize: "40px",
                                color: "white",
                                transform: "translate(-50%,-50%)",
                              }}
                            >{x.loading}</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="col-sm-12 text-right mt-3">
                  <input
                    type="submit"
                    value={
                      this.props.match.params.journalId > 0 ? "Update" : "Save"
                    }
                    id="saveType"
                    className="formButton formButtonBold"
                    onClick={this.saveHandle}
                  ></input>
                  <input
                    type="button"
                    value="Cancel"
                    className="formButtonBold cancelButton"
                    onClick={() => this.cancelHandle()}
                  ></input>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}