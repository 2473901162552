import React, { Component, Suspense } from "react";
import * as ReactDOM from "react-dom";
import docImg from "../image/icons/google-docs.png"
import docdelete from "../image/icons/delete.png"
import $ from "jquery"
import pdfImg from "../image/icons/pdf.png";
import AWS from "aws-sdk";
import { apiCalling } from "../apiService";
var s3;
let countFlg=1;
export default class healthDocumentList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            healthRecords: this.props.healthRecords,
            fileChange:"",
            file:"",
            AwsCheckAccessKey: "",
            AWSAccessKeyId: "",
            region: "us-east-1",
            bucket: "",
            bucketFolder:"",
            errorMessage: "Fetching data... "

        }
     
    }
  
componentWillReceiveProps(){
   this.setState({healthRecords: this.props.healthRecords})
   this.getMedicalRecord(); 
}


    componentDidMount() {
        this.getMedicalRecord();    
      // alert(this.props.healthRecords)  
      
      this.setState({healthRecords: this.props.healthRecords})
        $(".healthDocSub").click(function () {
            $(this).toggleClass("healthDocSubActive");
            $(".healthDocSub #healthCheck1").attr('checked', 'checked');
        });
    }

    getMedicalRecord() {
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                functionName: "healthRecords/patient/showDetails",
                userId: window.sessionStorage.getItem("userId"),
                max: "0",
                offset: "10",
                fromInput: "",
                otp: "",
                mobileNumber: ""
            }),
        };
       // console.log(apiJson.body)
        apiCalling(apiJson).then((data) => {
            if (data.success === "1") {
                this.setState({
                    healthRecords: data.healthRecords,
                    errorMessage:data.healthRecords.length > 0 ? "" : "No data found"
                });
                
            }else{
              
            }
        });
    }

    deleteMedicalRecord(id) {     

        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                functionName: "healthRecords/patient/deleteDetails",
                recordId: id,                
            }),
        };
       // console.log(apiJson.body)
       
        if (window.confirm("Delete the document?")) {
            $(".loader").show();
        apiCalling(apiJson).then((data) => {
            if (data.success === "1") {
                alert("Record delete successfully");
                this.getMedicalRecord();
               $(".loader").hide();
            }
        });
    }
    }

    addMedicalRecord(id,name,link){

      $("#healthDocSub"+id).toggleClass("healthDocSubActive");
      let flag=true;
      var countInput= countFlg//parseInt($("#countInput").val()); 
     // console.log(countInput)
      for(var i=1;i<=countInput;i++){         
         if(parseInt($("#recordId"+i).val())===id){
            flag=false;
            $("#templateDiv"+id).remove();
         } 
      }
      if(flag===true){
      var count=countInput+1;  
      $("#countInput").val(count);    
      $("#appendDiv").append("<div class='col-md-12' id='newDiv"+count+"' />");
      const LazyPage = React.lazy(() => import("../patient/fileUpload"));
      ReactDOM.render(
      <div>
          <Suspense fallback={<div>Loading...</div>}>
          <section>
              <LazyPage count={count} docFileName ={name} fileName={''} recordId={id} imagePath={link}/>
          </section>
          </Suspense>
      </div>,
      document.getElementById("newDiv"+count)
      );
      }
      countFlg=countFlg+1;
    }

    
    render() {
       // alert(this.state.healthRecords.length)
        return (
            <div className="healthDocNew">
               
                <div class="healthDocListMain">
                    {this.state.healthRecords.length === 0 &&
                    <div  style={{fontSize:14, color:"red"}}    > {this.state.errorMessage}</div>
                    } 
                    {this.state.healthRecords.map((obj,ct) => {
                        var imageFile=obj.imageUrl
                        let imgFlag="0"
                         if (imageFile.match(/\.(pdf)$/)) {
                            imgFlag="1";                       
                            
                          }
                        return (
                            obj.deletedByUser==="0" &&
                            <div className="healthDocSub" title="click to add" id={"healthDocSub"+obj.recordId}
                           >
                                {/* <div className="healthDocSubCheck" for="healthCheck2">
                                    <label className="checkbox-main">
                                        <input type="checkbox" id="healthCheck2" className="healthCheckBox" />
                                        <span class="checkbox"></span>
                                    </label>
                                </div> */}
                                <div className="healthDocSubDel">
                                    <img src={docdelete} width="10" onClick={() =>this.deleteMedicalRecord(obj.recordId)} title="Delete Medical Record"></img>
                                </div>
                                <div className="healthDocSubImg"  onClick={() =>this.addMedicalRecord(obj.recordId,obj.description,obj.imageUrl)}>
                                   {imgFlag==="1" ?
                                    <img src={pdfImg} width="20"></img>
                                    :
                                    <img src={obj.imageUrl} width="20"></img>
                            }
                                </div>
                                <div className="healthDocSubDet"  onClick={() =>this.addMedicalRecord(obj.recordId,obj.description,obj.imageUrl)}>
                                    <div className="healthDocSubName">{obj.description}</div>
                                    <div className="healthDocSubDate"></div>
                                    <div className="healthDocSubDesc">{obj.createdDate}</div>
                                </div>
                            </div>
                            
                        )
                    })}
                </div>
            </div>
        )
    }
}