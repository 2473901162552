import React, { Component, Suspense } from "react";
import * as ReactDOM from "react-dom";
import { apiCalling } from "../apiService";
import docdelete from "../image/icons/delete.png"
import $ from "jquery"
import AWS from "aws-sdk";
import docImg from "../image/icons/google-docs.png"
import { uploadToS3 } from "../UploadFile"

var s3;
let tempCnt = 15;
export default class healthDocumentNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            healthRecords: [],
            fileChange: "",
            file: "",
            AwsCheckAccessKey: "",
            AWSAccessKeyId: "",
            region: "us-east-1",
            bucket: "",
            bucketFolder: "",


        }
        this.fileChange = this.fileChange.bind(this);
        this.awsCredentials();
    }
    componentDidMount() {
        this.awsCredentials();
    }
    fileChange(e) {
        let imageFile = e.target.files[0];
        if (!imageFile.name.match(/\.(jpg|jpeg|png|pdf)$/)) {
            alert("Add file(s) with only PDF,JPG,JPEG,PNG format")
            return false;
        }
        if (imageFile.size > 3145728) {
            alert("File size must under 3 MB!");
            return false;
        }
        else {
            // $(".loader").show();
            $("#fileData").show();
            this.setState({ file: e.target.files[0] });
            $("#fileData").attr("src", URL.createObjectURL(e.target.files[0]));
            //this.uploadButton();
            // $("#fileChangeFlag").val('1');
        }
    }
    uploadButton() {
        let flag = false;

        if (this.state.file === "") {
            alert("Please upload a file")
        }
        else if ($("#docFileName").val() === "") {
            alert("Please enter file name")
            $("#docFileName").focus();
        }
        else {
            flag = true;
        }
        if (flag === true) {
            this.fileUpload(this.state.file);

        }
    }
    fileUpload(file) {
        $(".loader2").show();
        // AWS.config.update({
        //     accessKeyId: this.state.AwsCheckAccessKey,
        //     secretAccessKey: this.state.AWSAccessKeyId,
        //     region: this.state.region,
        // });
        // var bucketParams = {
        //     Bucket: this.state.bucket,
        // };
        // s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
        // let S3BucketMainFolder = this.state.bucketFolder + "/attachments";
        let fileName = this.imagepath1() + file.name;
        // let fullFilePath = S3BucketMainFolder + "/" + fileName;
        this.setState({ fileName: fileName });
        const s3Url = uploadToS3(file, this.state.bucketFolder + "/attachments/", fileName, this.state.bucket)
        if (s3Url) {
            $(".loader2").hide();
            alert("Uploaded Successfully");
            //$("#fileDeleteButton").show();
            let docFileName = $("#docFileName").val();
            this.setState({ file: "" });
            $("#docFileName").val("");
            $("#fileData").attr("src", "");
            this.uploadDocFile_new(docFileName, s3Url, fileName)
            return null;
        }
        else {
            $(".loader2").hide();
            alert("There was an error uploading your photo: ");
        }

        // s3.upload(
        //     {
        //         Key: fullFilePath,
        //         Body: file,
        //         ACL: "public-read",
        //     },
        //     (err, data) => {


        //         if (err) {

        //             $(".loader2").hide();
        //             alert("There was an error uploading your photo: ", err.message);
        //         } else if (data) {

        //             $(".loader2").hide();
        //             alert("Uploaded Successfully");
        //             //$("#fileDeleteButton").show();
        //             let docFileName = $("#docFileName").val();
        //             this.setState({ file: "" });
        //             $("#docFileName").val("");
        //             $("#fileData").attr("src", "");
        //             this.uploadDocFile_new(docFileName, data.Location, fileName)
        //             return null;
        //         }
        //     }
        // );
    }
    imagepath1 = () => {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (
            c
        ) {
            var r = (Math.random() * 16) | 0,
                v = c === "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    };
    awsCredentials() {
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                functionName: "imagePath",
                siteId: window.sessionStorage.getItem("siteId"),
            }),
        };
        apiCalling(apiJson).then((data) => {
            if (data.success === "1") {
                // this.setState({
                //     AwsCheckAccessKey: data.configPathDetails[0].AwsCheckAccessKey,
                // });
                // this.setState({
                //     AWSAccessKeyId: data.configPathDetails[0].AwsCheckSecretKeyId,
                // });

                this.setState({
                    bucket: data.configPathDetails[0].s3BucketReliance,
                });
                this.setState({
                    bucketFolder: data.configPathDetails[0].S3BucketMainFolder,
                });

            }
        });
    }

    uploadDocFile_new(docFileName, imagePath, fileName) {
        var count = tempCnt//parseInt($("#countInput").val())+1;  
        $("#countInput").val(count);
        $("#appendDiv").append("<div class='col-md-12' id='newDiv" + count + "' />");
        const LazyPage = React.lazy(() => import("../patient/fileUpload"));
        ReactDOM.render(
            <div>
                <Suspense fallback={<div>Loading...</div>}>
                    <section>
                        <LazyPage count={count} recordId={''} docFileName={docFileName} fileName={fileName} imagePath={imagePath} />
                    </section>
                </Suspense>
            </div>,
            document.getElementById("newDiv" + count)
        );
        tempCnt = tempCnt + 1;
    }

    // srcTypeImage(ev){
    //     ev.target.src = docImg;
    //   }


    render() {
        return (
            <div>
                <div className="healthDocNew row sh-file-thumbnail">
                    <div className="health-record-head px-3 py-2" style={{ border: "none" }}> You can add a health/medical record (Optional) </div>
                    {/*  */}

                    <div class="col-sm-12 health-records-files">
                        <div className="row form-group">
                            <div class="col-lg-2  col-sm-2 health-records-steps ">
                                <h3>Step 1</h3>
                            </div>
                            <div class="col-lg-4  col-sm-5 health-records-opt">
                                <div class="fileUpload-medical">
                                    <span>Select File</span>
                                    <input type="button" id="attach" name="attach" class="upload" />
                                    <input type="file" class="upload" onChange={this.fileChange} />
                                </div>
                            </div>
                            <div className="fileRecordThumb">
                                <img src="" id={"fileData"} name="srcFileType" className="fileRecordThumbImg"></img>
                            </div>
                        </div>
                        <div className="row form-group">
                            <div class="col-lg-2  col-sm-2 health-records-steps">
                                <h3>Step 2</h3>
                            </div>
                            <div class="col-lg-6  col-sm-8 col-xs-10 health-records-opt">
                                <input type="text" id="docFileName" className="input-design  " placeholder="Name the Document" />
                            </div>
                        </div>
                        <div className="row form-group">
                            <div class="col-lg-2  col-sm-2  health-records-steps">
                                <h3>Step 3</h3>
                            </div>
                            <div class="col-lg-4  col-sm-4 health-records-opt">
                                <input
                                    type="button"
                                    value="Upload File"
                                    onClick={() => this.uploadButton()}
                                    className="fileUpload-medical fileUpload-medical-sm "
                                    title="Click to Add "
                                ></input>
                            </div>
                        </div>
                    </div>
                    {/*  */}
                    {/* <div className="row-margin col-sm-4 col-lg-3 pr-0">
                        <div class="fileUpload-medical fileUpload-medical-sm">
                            <span>Attach file</span>
                            <input type="button" id="attach" name="attach" class="upload" />
                            <input type="file" class="upload" onChange={this.fileChange} />
                        </div>
                    </div>  */}

                    {/* <div className="row-margin col-sm-6 col-10 pl-1 p-md-0">
                        <input type="text" id="docFileName" className="input-design  " placeholder="Document Name" />
                    </div>                   
                    <div className="row-margin col-sm-2 col-2">
                        <input type="button"  
                    onClick={() =>this.uploadDocFile()}                            
                    value="+" className="formButton formButton-sm" ></input>
                    <input
                        type="button"
                        value="Upload File"
                        onClick={() =>this.uploadButton()}
                        className="fileUpload-medical fileUpload-medical-sm "
                        title="Click to Add "
                    ></input>
                        <span class=" fa fa-plus" style={{ color: "green" }} onClick={() =>this.uploadButton()}  title="Upload document"></span>
                    </div>
                    <div className="col-md-12">
                      <p class="note">
                      Supporting File Types(PNG,JPG,JPEG,PDF)
                      </p>
                    </div> */}

                </div>
                <div id="appendDiv" className="row">
                </div>

            </div>
        )
    }
}